import React, { useEffect } from "react";
import NavBar from "../../components/NavBar";
import Container, {
  ContainerDescription
} from "../../components/Container/Container";
import PagesContainer from "../../components/PagesContainer";
import PagesCalculation from "../../components/Tools/WTPConverter/pages-calculation";

const WTPConverterPage = () => {
  useEffect(() => {
    PagesCalculation();
  }, []);

  return (
    <PagesContainer>
      <NavBar />
      <Container className="">
        <h1>Words to pages converter</h1>
        <ContainerDescription>
          Enter a number of words or paste your text & choose the formatting
        </ContainerDescription>
        <div className="cabinet-calculation">
          <div className="calculation_container">
            <div className="orderform f uvoform_potato page-calc-uvoform_potato">
              <div className="page-calc-table table">
                <div className="row page-calc-row" id="box_textdets">
                  <div className="cell cell-left">
                    <div className="with-mobile-tip">
                      <label className="left-label" htmlFor="wordsreq">
                        Number of words
                      </label>
                    </div>
                    <div className="page-calc-contentspin contentspin page-calc-clearfix clearfix">
                      <input
                        type="number"
                        min="1"
                        max="1000"
                        minval="0"
                        className="page-calc-words f_sz_30"
                        maxLength="1"
                        name="wordsreq"
                        id="wordsreq"
                        defaultValue="750"
                      />
                    </div>
                    <div className="page-calc-syf-text">or</div>
                    <div
                      className="words-calculation-tip"
                      style={{ display: "none" }}
                    />
                  </div>
                  <div className="cell cell-right">
                    <div className="with-mobile-tip">
                      <label className="left-label" htmlFor="">
                        Paste text
                      </label>
                    </div>
                    <div className="side_tip side_tip_textdets">
                      <textarea
                        placeholder="Paste text..."
                        id="textdets"
                        rows="1"
                        className="expand50-200 f_sz_full page-calc-textarea"
                        name="textdets"
                      />
                      <div
                        id="textdets-active-panel"
                        className="textdets-active-panel"
                        style={{ display: "none" }}
                      >
                        <button
                          className="page-calc-button"
                          id="text-clear-button"
                        >
                          Clear
                        </button>
                        <div id="characters" className="page-calc-characters" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ui-buttonset" id="box_fontfamily">
                  <div className="">
                    <label className="left-label" htmlFor="">
                      Font Family
                    </label>
                  </div>
                  <div className="side_tip side_tip_font_family">
                    <div className="radios" id="fontfamily">
                      <input
                        type="radio"
                        data-finder="form.input.fontfamily1"
                        name="fontfamily"
                        value="Arial"
                        id="radio_font_family_1"
                        title="Font family Arial"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.fontfamily1"
                        id="tip_radio_font_family_1"
                        htmlFor="radio_font_family_1"
                        className="ui-button ui-widget ui-state-default ui-button-text-only ui-corner-left"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">Arial</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.fontfamily2"
                        name="fontfamily"
                        value="Calibri"
                        id="radio_font_family_2"
                        title="Font family Calibri"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.fontfamily2"
                        id="tip_radio_font_family_2"
                        htmlFor="radio_font_family_2"
                        className="ui-button ui-widget ui-state-default ui-button-text-only ui-corner-right"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">Calibri</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.fontfamily3"
                        name="fontfamily"
                        value="Comic Sans"
                        id="radio_font_family_3"
                        title="Font family Comic Sans"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.fontfamily3"
                        id="tip_radio_font_family_3"
                        htmlFor="radio_font_family_3"
                        className="ui-button ui-widget ui-state-default ui-button-text-only"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">Comic Sans</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.fontfamily4"
                        name="fontfamily"
                        value="Courier"
                        id="radio_font_family_4"
                        title="Font family Courier"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.fontfamily4"
                        id="tip_radio_font_family_4"
                        htmlFor="radio_font_family_4"
                        className="ui-button ui-widget ui-state-default ui-button-text-only"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">Courier</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.fontfamily5"
                        name="fontfamily"
                        value="Verdana"
                        id="radio_font_family_5"
                        title="Font family Verdana"
                        className="ui-helper-hidden-accessible"
                        defaultChecked={true}
                      />
                      <label
                        data-finder="form.label.fontfamily5"
                        id="tip_radio_font_family_5"
                        htmlFor="radio_font_family_5"
                        className="ui-button ui-widget ui-state-default ui-button-text-only ui-corner-right  ui-state-active"
                        role="button"
                        aria-disabled="true"
                        aria-pressed="true"
                      >
                        <span className="ui-button-text">Verdana</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.fontfamily6"
                        name="fontfamily"
                        value="Times New Roman"
                        id="radio_font_family_6"
                        title="Font family Times New Roman"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.fontfamily6"
                        id="tip_radio_font_family_6"
                        htmlFor="radio_font_family_6"
                        className="ui-button ui-widget ui-state-default ui-button-text-only ui-corner-right"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">Times New Roman</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className="row ui-buttonset page-calc-box_fontsize"
                  id="box_fontsize"
                >
                  <div className="">
                    <label className="left-label" htmlFor="">
                      Font size
                    </label>
                  </div>
                  <div className="side_tip side_tip_font_size">
                    <div className="radios" id="fontsize">
                      <input
                        type="radio"
                        data-finder="form.input.fontsize10"
                        name="fontsize"
                        value="9"
                        id="radio_font_size_9"
                        title="Font size 9"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.radiofontsize9"
                        id="tip_radio_paper_format_1"
                        htmlFor="radio_font_size_9"
                        className="ui-button ui-widget ui-state-default ui-button-text-only"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">9</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.fontsize10"
                        name="fontsize"
                        value="10"
                        id="radio_font_size_10"
                        title="Font size 10"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.radiofontsize10"
                        id="tip_radio_paper_format_2"
                        htmlFor="radio_font_size_10"
                        className="ui-button ui-widget ui-state-default ui-button-text-only ui-corner-left"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">10</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.fontsize11"
                        name="fontsize"
                        value="11"
                        id="radio_font_size_11"
                        title="Font size 11"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.radiofontsize11"
                        id="tip_radio_paper_format_3"
                        htmlFor="radio_font_size_11"
                        className="ui-button ui-widget ui-state-default ui-button-text-only"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">11</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.fontsize12"
                        name="fontsize"
                        value="12"
                        id="radio_font_size_12"
                        title="Font size 12"
                        className="ui-helper-hidden-accessible"
                        defaultChecked={true}
                      />
                      <label
                        data-finder="form.label.radiofontsize12"
                        id="tip_radio_paper_format_4"
                        htmlFor="radio_font_size_12"
                        className="ui-button ui-widget ui-state-default ui-button-text-only ui-state-active"
                        role="button"
                        aria-disabled="true"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">12</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.fontsize13"
                        name="fontsize"
                        value="13"
                        id="radio_font_size_13"
                        title="Font size 13"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.radiofontsize13"
                        id="tip_radio_paper_format_5"
                        htmlFor="radio_font_size_13"
                        className="ui-button ui-widget ui-state-default ui-button-text-only"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">13</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.fontsize14"
                        name="fontsize"
                        value="14"
                        id="radio_font_size_14"
                        title="Font size 14"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.radiofontsize14"
                        id="tip_radio_paper_format_6"
                        htmlFor="radio_font_size_14"
                        className="ui-button ui-widget ui-state-default ui-button-text-only ui-corner-right"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">14</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row ui-buttonset" id="box_spacing">
                  <div className="">
                    <label className="left-label" htmlFor="">
                      Spacing
                    </label>
                  </div>
                  <div className="spacing-inliner">
                    <div className="radios radios-spacing" id="spacing">
                      <input
                        type="radio"
                        data-finder="form.input.spacingsingle"
                        value="one_spacing"
                        id="spacing_single"
                        name="spacing"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.spacingsingle"
                        htmlFor="spacing_single"
                        id="spacing_single_btn"
                        className="ui-button ui-widget ui-state-default ui-button-text-only ui-corner-left"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">Single</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.spacingonehalf"
                        value="one_point_five_spacing"
                        id="spacing_onehalf"
                        name="spacing"
                        className="ui-helper-hidden-accessible"
                      />
                      <label
                        data-finder="form.label.spacingonehalf"
                        htmlFor="spacing_onehalf"
                        id="spacing_onehalf_btn"
                        className="ui-button ui-widget ui-state-default ui-button-text-only"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="false"
                      >
                        <span className="ui-button-text">1.5</span>
                      </label>
                      <input
                        type="radio"
                        data-finder="form.input.spacingdouble"
                        value="two_spacing"
                        id="spacing_double"
                        name="spacing"
                        className="ui-helper-hidden-accessible"
                        defaultChecked={true}
                      />
                      <label
                        data-finder="form.label.spacingdouble"
                        htmlFor="spacing_double"
                        id="spacing_double_btn"
                        className="ui-button ui-widget ui-state-default ui-button-text-only ui-corner-right ui-state-active"
                        role="button"
                        aria-disabled="false"
                        aria-pressed="true"
                      >
                        <span className="ui-button-text">Double</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="result-block" id="box_result">
                  <div className="result_container page-calc-result_container">
                    <h3 className="result-header light">Result</h3>
                    <div className="sheets">
                      <span id="sheets_sp" /> sheets
                    </div>
                    <div className="result_box">
                      <span id="pgs_sp" /> pages
                    </div>
                    <div
                      className="result_sheet page-calc-result_sheet"
                      id="dr_pages"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </PagesContainer>
  );
};

export default WTPConverterPage;
