import React, { useEffect } from "react";
import PagesContainer from "../../components/PagesContainer";
import NavBar from "../../components/NavBar";
import Container, {
  ContainerDescription
} from "../../components/Container/Container";
import "../../components/Tools/WTMConverter/wtm-converter.css";
import useScript from "../../hooks/addScript";
import styled from "styled-components";

window.renderWtmConverter = () => {};

const WTMConverterPage = () => {
  const [loaded, error] = useScript({
    src: `${process.env.PUBLIC_URL}/WTMConverter/wtm-converter.js`
  });

  useEffect(() => {
    if (loaded && !error) {
      window.renderWtmConverter("#wtm-converter", {
        strings: {
          "speech-block-textarea-placeholder": "A well-prepared speech or presentation can play a crucial role in academic or business life. On the one hand, it helps you stand out from others while selling a certain product to your potential clients. It is also a good way to demonstrate your knowledge in bullet points to your professor. Both a speech or presentation serve as your brand, but only if they are balanced with a thorough preparation. At writingcities.net, we are able to lay the foundation for you to successfully achieve all these goals. Our main focus is on customer’s specific circumstances, requirements, and the purpose of speech. Due to our careful approach and skillful writers, we have been effectively assisting our customers over 11 years.",
        }
      });
    }
  });

  return (
    <PagesContainer>
      <NavBar />
      <Container>
        <h1>Words to Minutes Converter</h1>
        <ContainerDescription>
          Figure out how many words your speech or presentation should have.
          Indicate either the number of words of minutes by using the switch
          button.
        </ContainerDescription>
        {loaded && error ? (
          <Error>Oops! Something went wrong! :(</Error>
        ) : (
          <div id="wtm-converter" className="wtm-container" />
        )}
      </Container>
    </PagesContainer>
  );
};

const Error = styled.div`
  padding: 2rem 0;
`;

export default WTMConverterPage;
